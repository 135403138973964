import React from "react"
import cx from "classnames"

import { term } from "../../mock/termo-de-uso"
import Menu from "../Menu"
import Footer from "../Footer"

import * as styles from "./styles.module.css"

const Termo = () => {
  const gridClass = cx("m-auto main-container")
  const containerClass = cx("flex flex-col", global.pagesDPCandPP)
  const titleClass = cx("text-white font-bold mb-4", styles.title)
  const textClass = cx("text-white sm:text-sm md:text-base", styles.text)

  return (
    <div className={gridClass}>
      <Menu pageName="termos-de-uso" />
      <div className="flex justify-center">
        <div className={containerClass}>
          <h1 className={titleClass}>{term.title}</h1>
          <p
            className={textClass}
            dangerouslySetInnerHTML={{ __html: term.text }}
          />
          <Footer />
        </div>
      </div>
    </div>
  )
}
export default Termo
